<template>
    <div class="express">
        <full-page :options="options" id="fullpage2">
            <div class="section bg">
                <div class="section-all">
                    <div class="express__kj animate__animated animate__fadeInUp">
                        
                    </div>
                    <div class="express__font animate__animated animate__fadeInUp">
                        
                        <span class="size64">快车</span>
                        <span class="size36">以快速的响应、实惠的价格，为用户提供便捷、高效的出行服务</span>
                    </div>
                </div>
            </div>
            <div class="section">
                <div class="section-all">
                    <div class="express-title">
                        <span class="size36">用车体验</span>
                        <span class="size16">Experience</span>
                    </div>
                    <div class="express-list">
                        <div class="express-list__li">
                            <div class="express-list__li-center">
                                <img class="one" :class="listTop" src="../../../static/images/kc/exp-one.png" />
                                <div class="one-img one-bg">
                                    <span>01</span>
                                </div>
                                <span class="size36" :class="listBottom">安全合规</span>
                            </div>
                        </div>
                        <div class="express-list__li">
                            <div class="express-list__li-center">
                                <img class="two" :class="listTop" src="../../../static/images/kc/exp-two.png" />
                                <div class="one-img two-bg">
                                    <span>02</span>
                                </div>
                                <span class="size36" :class="listBottom">拒绝溢价</span>
                            </div>
                        </div>
                        <div class="express-list__li">
                            <div class="express-list__li-center">
                                <img class="two" :class="listTop" src="../../../static/images/kc/exp-three.png" />
                                <div class="one-img three-bg">
                                    <span>03</span>
                                </div>
                                <span class="size36" :class="listBottom">专职司机</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="section">
                <div class="section-all">
                    <div class="all-title">
                        <span class="size36">我们的产品</span>
                        <span class="size16">Product</span>
                    </div>
                    <div class="express-bocontent">
                        <div class="express-menu"
                        :class="[expreIndex == 0 ? '' : 'express-menu__ac']">
                            <div class="express-menu__li"
                            :class="[expreIndex == 0 ? 'express-menu__active' : '']"
                            @click="expreTap(0)">乘客端</div>
                            <div class="express-menu__li"
                            :class="[expreIndex == 1 ? 'express-menu__active' : '']"
                            @click="expreTap(1)">司机端</div>
                        </div>
                        <div class="express-swiper">
                            <!-- 右边的图片和箭头 -->
                            <div class="express-swiper__content" :class="swiperRight">
                                <swiper class="swiper" :options="swiperOption"
                                ref="Myswiper"
                                @slideChange="changeswiper"
                                v-if="swiperShow">
                                    <swiper-slide v-for="(item,index) in ckList"
                                    :key="index+'p'">
                                    <div class="express-swiper__left">
                                        <span class="express-swiper__h">{{item.id}}</span>
                                        <div class="express-swiper__con">
                                            <h2 class="size28">{{item.title}}</h2>
                                            <p class="size16">
                                                {{item.memo}}
                                            </p>
                                        </div>
                                    </div>
                                    </swiper-slide>
                                </swiper>
                                <div class="express-swiper__naction">
                                    <!-- swiper-container-prev 按钮上一个 -->
                                    <div class="swiper-container-prev swiper-container__na">
                                        <img src="../../../static/images/home/swiper-left.png" />
                                    </div>
                                    <!-- swiper-container-next 按钮下一个 -->
                                    <div class="swiper-container-next swiper-container__na ">
                                        <img src="../../../static/images/home/swiper-right.png" />
                                    </div>
                                </div>
                            </div>
                            <!-- 左边的图片  -->
                            <div class="express-swiper__img" :class="swiperLeft">
                                <swiper class="swiper" :options="swiperOption"
                                ref="Myswiper"
                                @slideChange="changeswiper"
                                v-if="swiperShow">
                                    <swiper-slide v-for="(item,index) in ckList"
                                    :key="index+'p'">
                                    <img :src="item.img" />
                                    </swiper-slide>
                                </swiper>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </full-page>
    </div>
</template>
<script>
import '../../../static/css/fullpage.min.css';
// 引入插件
// import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
// import 'swiper/css/swiper.css'
export default {
    // components: {
    //     Swiper,
    //     SwiperSlide
    // },
    data(){
        return{
            options:{
                afterRender:this.afterRender,
                navigation:true,
                onLeave:this.onLeave
            },
            expreIndex:0,
            swiperOption:{
                speed:600,
                loop:true,
                nextButton:'.swiper-container-next',
                prevButton:'.swiper-container-prev',
                effect : 'fade',
                simulateTouch : false
            },
            ckList:[
                {
                    title:'推荐地点，即刻出发',
                    id:'01',
                    memo:'拖动地图即可切换到适合上车的推荐地点，司机接驾更快，出行效率更高',
                    img:require('../../../static/images/kc/ck-one.png')
                },
                {
                    title:'便捷叫车 一触即发',
                    id:'02',
                    memo:'随时叫车出发或提前预约某一时间段行程需求，随时为您调度附近的车辆',
                    img:require('../../../static/images/kc/ck-two.png')
                },
                {
                    title:'路线透明 实时定位',
                    id:'03',
                    memo:'行程路线清晰，地图界面实时追踪车辆行驶路径',
                    img:require('../../../static/images/kc/ck-three.png')
                },
                {
                    title:'实时沟通，安全接驾',
                    id:'04',
                    memo:'通过发送实时消息、隐私虚拟号码随时与司机保持联系，安全出行更省心',
                    img:require('../../../static/images/kc/ck-four.png')
                },
                {
                    title:'一键开票，便捷报销',
                    id:'05',
                    memo:'通勤、商务出行一键提交即可轻松报销，支持纸质发票和电子发票',
                    img:require('../../../static/images/kc/ck-five.png')
                }
            ],
            ckIndex:0,
            swiperShow:true,
            swiperLeft:'',
            swiperRight:'',
            listTop:'',
            listBottom:''
        }
    },
    computed:{
        swiper(){
            return this.$refs.Myswiper.swiper;
        }
    },
    mounted(){
        this.routerTo(3);
    },
    methods:{
        expreTap(index){
            this.expreIndex = index;
            this.ckIndex = 0;
            this.swiperShow = false;
            setTimeout(()=>{
                this.swiperShow = true;
            },50)
            switch(index){
                case 0:
                  this.ckList = [
                        {
                            title:'推荐地点，即刻出发',
                            id:'01',
                            memo:'拖动地图即可切换到适合上车的推荐地点，司机接驾更快，出行效率更高',
                            img:require('../../../static/images/kc/ck-one.png')
                        },
                        {
                            title:'便捷叫车 一触即发',
                            id:'02',
                            memo:'随时叫车出发或提前预约某一时间段行程需求，随时为您调度附近的车辆',
                            img:require('../../../static/images/kc/ck-two.png')
                        },
                        {
                            title:'路线透明 实时定位',
                            id:'03',
                            memo:'行程路线清晰，地图界面实时追踪车辆形式路径',
                            img:require('../../../static/images/kc/ck-three.png')
                        },
                        {
                            title:'实时沟通，安全接驾',
                            id:'04',
                            memo:'通过发送实时消息、隐私虚拟号码随时与司机保持联系，安全出行更省心',
                            img:require('../../../static/images/kc/ck-four.png')
                        },
                        {
                            title:'一键开票，便捷报销',
                            id:'05',
                            memo:'通勤、商务出行一键提交即可轻松报销，支持纸质发票和电子发票',
                            img:require('../../../static/images/kc/ck-five.png')
                        }
                    ]
                  break;
                case 1:
                  this.ckList = [
                      {
                            title:'人脸识别 认证上岗',
                            id:'01',
                            memo:'司机出车需要进行AI人脸识别，身份认证通过后方能上线接单',
                            img:require('../../../static/images/kc/sj-one.png')
                        },
                        {
                            title:'界面清晰 操作便捷',
                            id:'02',
                            memo:'司机端界面简单清晰，行程一目了然，上手简单，操作便捷',
                            img:require('../../../static/images/kc/sj-two.png')
                        },
                        {
                            title:'优质订单  精准推送',
                            id:'03',
                            memo:'海量优质订单，智能推送算法，高效撮合司机和乘客，达到供需匹配。',
                            img:require('../../../static/images/kc/sj-three.png')
                        },
                        {
                            title:'精准导航  高效接驾',
                            id:'04',
                            memo:'高精度地图定位，精准导航路线，即时通讯等保证司机更快找到乘客位置，高效接驾',
                            img:require('../../../static/images/kc/sj-four.png')
                        },
                        {
                            title:'收入明细 随时可查',
                            id:'05',
                            memo:'司机收入流水实时展示，统计细致，记录全面，明细清晰，随时可供查对。',
                            img:require('../../../static/images/kc/sj-five.png')
                        }
                  ]
                  break;
            }
        },
        afterRender(){

        },
        // fullpage的onleave事件  onleave(index,nextIndex,direction)  
        // index是离开的“页面”的序号，从1开始计算；nextIndex是滚动到的目标“页面”的序号，从1开始计算；direction判断往上滚动还是往下滚动，值是up或down。
        onLeave(e,n){
            const {index} = n;
            switch(index){
                case 1:
                  this.listTop = 'animate__animated animate__fadeInDown';
                  this.listBottom = 'animate__animated animate__fadeInUp'
                  break;
                case 2:
                  this.swiperLeft = 'animate__animated animate__fadeInLeft';
                  this.swiperRight = 'animate__animated animate__fadeInRight';
                  break;
            }
        },
        changeswiper(){
            this.ckIndex = this.$refs.Myswiper.$swiper.activeIndex;
        }
    }
}
</script>
<style lang="less" scoped>
@import url('../../../static/css/express.less');
@import url('../../../static/css/media.less');
</style>